class MembersTable {
    constructor(ele) {
        new DataTable(ele, {
            lengthChange: false,
            pagingType: 'simple_numbers',
            autoWidth: false,
            language: {
                info: "Showing _START_-_END_ of _TOTAL_",
                infoEmpty: "No members available",
                paginate: {
                    previous: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.021 16.193"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m7.901 14.07-6.4-5.977 6.4-5.977"/></svg>',
                    next: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.021 16.193"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M2.12 2.123 8.52 8.1l-6.4 5.977"/></svg>',
                },
                aria: {
                    paginate: {
                        previous: 'Previous',
                        next: 'Next',
                    }
                },
                emptyTable: "Sorry, no members could be found.",
                zeroRecords: "Sorry, no members could be found."
            },
            dom: 'ft<"wba-table-bottom"ip>',
        });
    }
}

export default MembersTable;
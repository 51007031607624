import MembersTable from "./classes/MembersTable";
import LoginForm from "./classes/LoginForm";
import ProfileForm from './classes/ProfileForm';
import LogoutForm from "./classes/LogoutForm";

window.$ = jQuery;

let loginForms = document.querySelectorAll('[data-login]');
loginForms.forEach(form => {
    new LoginForm(form);
});

let tables = document.querySelectorAll('.wba-members-table');
tables.forEach(table => {
    new MembersTable(table);
});

let profileForms = document.querySelectorAll('.wba-profile-form');
profileForms.forEach(form => {
    new ProfileForm(form);
});

let logoutForms = document.querySelectorAll('[data-logout]');
logoutForms.forEach(form => {
    new LogoutForm(form);
});
import AjaxForm from "../components/AjaxForm";

class ProfileForm extends AjaxForm {
    constructor(form) {
        super(form);

        this.addEventListeners();
    }

    addEventListeners(){
        this.form.addEventListener('submit', (e) => {
            this.onSubmit(e)
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setSubmitButtonState({
            isDisabled: true,
            text: 'Submitting...'
        });
        
    this.clearErrors();
        
        let res = await fetch(this.form.action, {
            method: 'POST',
            body: new FormData(this.form),
        });

        let body = await res.json();

        if (res.status === 200) {
            this.handleSuccess(body);
        } else {
            this.handleFailure(body.data);
        }

        this.setSubmitButtonState({
            isDisabled: false,
            text: 'Submit'
        });
    }
}

export default ProfileForm;
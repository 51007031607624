class AjaxForm {
    constructor(form) {
        this.form = form;
        this.submitButton = form.querySelector('button[type="submit"]');
    }

    /**
     * Handle failure, including showing input errors and general form errors
     *
     * @param   {Object}  data
     *
     * @return  {void}
     */
    handleFailure(data) {
        if (data?.formError) {
            this.setFormError(data.formError);
        }
        if(data?.errors){
            this.setInputErrors(data.errors);
        }
    }

    /**
     * Handle success, including showing elements, redirects and showing success messages
     *
     * @param   {Object}  body
     *
     * @return  {void}
     */
    handleSuccess(body) {
        if (body.show) {
            return this.showElement(body.show);
        }

        if (body.redirect) {
            window.location.href = body.redirect;
        }

        if (body.success) {
            this.setFormSuccess(body.success);
        }
    }

    /**
     * Show an element
     *
     * @param   {string}  selector  HTML selector
     *
     * @return  {void}
     */
    showElement(selector) {
        const element = this.form.querySelector(selector);
        
        if (!element) {
            return;
        }

        element.classList.remove('-wba-hide');
    }

    /**
     * Clear all errors and success messages
     *
     * @return  {void}
     */
    clearErrors() {
        let errors = this.form.querySelectorAll('[data-error]');
        errors.forEach(error => {
            error.innerHTML = '';
        });

        let success = this.form.querySelectorAll("[data-success]");
        success.forEach(ele => {
            ele.innerHTML = '';
        });
    }

    /**
     * Show the error message
     *
     * @param   {string}  error  Error message
     *
     * @return  {void}
     */
    setFormError(error) {
        let formError = this.form.querySelector('[data-error="form"]');

        if (!formError) {
            return;
        }

        formError.innerHTML = error;
    }

    /**
     * Show the success message
     *
     * @param   {string}  successMessage
     *
     * @return  {void}
     */
    setFormSuccess(successMessage) {
        let successContainer = this.form.querySelector('[data-success="form"]');

        if (!successContainer) {
            return;
        }

        successContainer.innerHTML = successMessage;
    }

    /**
     * Show the input errors
     *
     * @param   {Object}  errors  Key => Error
     *
     * @return  {void}
     */
    setInputErrors(errors) {
        for (const [key, messages] of Object.entries(errors)) {
            let errorContainer = this.form.querySelector(`[data-error="${key}"]`);

            let errorMsg = messages.join(' ');

            if (errorContainer) {
                errorContainer.innerHTML = errorMsg;
            }
        }
    }

    /**
     * Set the state of the submit button
     *
     * @param   {boolean}  isDisabled
     * @param   {string}  text        Text to put inside the button
     *
     * @return  {void}
     */
    setSubmitButtonState({isDisabled, text}){
        this.submitButton.disabled = isDisabled;
        this.submitButton.innerHTML = text;
    }
}

export default AjaxForm;
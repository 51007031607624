import AjaxForm from "../components/AjaxForm";

class LoginForm extends AjaxForm {
    constructor(form) {
        super(form);

        this.typeOptions = form.querySelector('.wba-type-options');

        this.addEventListeners();
    }

    addEventListeners() {
        this.form.addEventListener('submit', e => {
            this.onSubmit(e);
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setSubmitButtonState({
            isDisabled: true,
            text: 'Submitting...'
        });

        this.clearErrors();

        let data = this.getFormData();

        let response = await fetch(this.form.action, {
            method: 'POST',
            body: data
        });

        let body = await response.json();

        if (response.status === 200) {
            this.handleSuccess(body);

            this.setSubmitButtonState({
                isDisabled: false,
                text: body.show ? 'Confirm' : 'Submit'
            });
        } else {
            this.handleFailure(body.data);

            this.setSubmitButtonState({
                isDisabled: false,
                text: 'Submit'
            });
        }
    }

    getFormData() {
        let formData = new FormData(this.form);

        if (this.isTypeOptionsShowing()) {
            return formData;
        }

        formData.delete('type');

        return formData;
    }

    isTypeOptionsShowing() {
        return !this.typeOptions.classList.contains('-wba-hide');
    }
}

export default LoginForm;
import AjaxForm from "../components/AjaxForm";

class LogoutForm extends AjaxForm {
    constructor(form) {
        super(form);

        this.addEventListeners();
    }

    addEventListeners() {
        this.form.addEventListener('submit', e => {
            this.onSubmit(e);
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        document.body.classList.add('-wba-wait');

        let response = await fetch(this.form.action, {
            method: 'POST',
        });

        let body = await response.json();

        if (response.status === 200) {
            this.handleSuccess(body)
        } else {
            console.error('Unable to logout.');
        }

        document.body.classList.remove('-wba-wait');
    }
}

export default LogoutForm;